import React from "react";
import "../css/promotioncoupon.css";
const PromotionCoupon = () => {
  return (
    <div id="scroll-container">
      <div id="scroll-text">
        Get 10% OFF on the first order! Use code SIGNUP10
      </div>
      <div id="scroll-text"></div>
    </div>
  );
};

export default PromotionCoupon;
