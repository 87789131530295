import React, { useState, useEffect } from "react";
import cake from "../images/Rectangle48.webp";
import logo from "../images/logo.png";
import "../css/login.styles.css";
import { Form, Button } from "react-bootstrap";
import app from "../firebase/Firebase.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import useStore from "../utils/store";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet";

const Login = (props) => {
  let navigate = useNavigate();

  const auth = getAuth(app);

  const setPhoneNumber = useStore((state) => state.setPhoneNumber);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const setResult = useStore((state) => state.setResult);
  const user = useStore((state) => state.user);

  const [isLoading, setIsLoading] = useState("");
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");

  function setUpRecaptha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" },
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }

  const getOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (number === "" || number === undefined)
      return toast("Enter valid number", { type: "error" });
    try {
      //for signup checking with document id oc customer
      setIsLoading(true);
      const phoneNumber = "+91" + number;
      const response = await setUpRecaptha(phoneNumber);
      setResult(response);
      setPhoneNumber(number);
      navigate("/verify", { replace: true });
      setIsLoading(false);
    } catch (err) {
      // alert(err)
      setIsLoading(false);
      // toast(err.message, { type: "error" });
    }
  };

  useEffect(() => {
    if (isLoggedIn !== undefined && isLoggedIn && user !== undefined) {
      navigate(`/`, { replace: true });
    }
  }, []);

  return (
    <div className="login-page">
      <Helmet>
        <title>Crème n Crisp | Login</title>
        <meta name="description" content="Login to Crème n Crisp" />
        <meta name="keywords" content="Login, Crème n Crisp" />
        <link rel="canonical" href="https://www.cremencrisp.com/login" />
      </Helmet>
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div className="login-main">
          <h2 className="heading">Login</h2>
          <div className="mid-sec">
            <img src={cake} alt="cake" className="cakeimg" />
            <div className="vl"></div>
            <div className="middle-right-div">
              <div className="mid-right">
                <img src={logo} alt="seal" className="sealimg" />

                <Form onSubmit={getOtp}>
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                  <Button type="submit" variant="primary" className="verify">
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
