import React from "react";
import "../css/footer.styles.css";
import footicon1 from "../images/fb.png";
import footicon2 from "../images/insta.png";
import footicon3 from "../images/wa.png";
import { useNavigate } from "react-router-dom";

const options = [
  "Home",
  "About Us",
  "Menu",
  // "Luxury Items",
  "Coupons",
  "Privacy Policy",
  "Terms & Conditions",
  "Refund Policy",
];

const optionsUrls = [
  "/",
  "/about-us",
  "/menu",
  // "/",
  "/coupon",
  "/privacy-policy",
  "/terms-and-conditions",
  "/refund-policy",
];

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer-page">
      <div className="footer-heading">Crème n Crisp</div>

      <div className="footer-category-link">
        {options.map((op, index) => (
          <div
            key={index}
            onClick={(e) => {
              e.preventDefault();
              navigate(optionsUrls[index]);
            }}
          >
            <ul className="items">
              <li className="footer-items">{op}</li>
            </ul>
          </div>
        ))}
      </div>
      <div className="footer-location">
        <h1 className="location-heading">Locations</h1>
        <div className="diff-locations">
          <div className="location-address">
            <div className="address-text">
              <a href="https://www.google.com/maps/place/Creme+N+Crisp/@28.541444,77.254836,15z/data=!4m5!3m4!1s0x0:0x2211ff95d797b316!8m2!3d28.5414437!4d77.2548361?hl=en">
                F17, Kalkaji Main Rd, Kalkaji Extension, Kalkaji, New
                Delhi-110019
              </a>
            </div>
            <div className="address-text">
              Ph No : 01149407415, +91 9650855873
            </div>
          </div>
          <div className="footer-vl"></div>
          <div className="location-address">
            <div className="address-text">
              <a href="https://www.google.com/maps/place/Cr%C3%A9me+n+Crisp/@28.632872,77.208413,15z/data=!4m5!3m4!1s0x0:0xc920a68e4c3d6984!8m2!3d28.6328722!4d77.2084134?hl=en">
                97, Bangla Sahib Rd, Sector 4, Gole Market, New Delhi, Delhi
                110001
              </a>
            </div>
            <div className="address-text">
              Ph No : 01123365127, +91 9310369299
            </div>
          </div>
          <div className="footer-vl"></div>
          <div className="location-address">
            <div className="address-text">
              <a href="https://www.google.com/maps?ll=28.674318,77.103891&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=16689904614899144688">
                A6/8, Ground Floor, East Paschim Vihar New Delhi Delhi-110063
              </a>
            </div>
            <div className="address-text">
              Ph No : 01140159018, +91 9821158852
            </div>
          </div>
        </div>
        <div className="online-order-text">
          Delivery Timings: 11:00 AM - 09:00PM
        </div>
        <div className="footer-icons">
          <a href="https://www.facebook.com/CremeNCrispPremium">
            <img src={footicon1} alt={footicon1} className="footicon" />
          </a>
          <a href="https://www.instagram.com/cremencrisppremium">
            <img src={footicon2} alt={footicon2} className="footicon" />
          </a>
          <a href="https://api.whatsapp.com/send?phone=919310369299&text=Hello">
            <img src={footicon3} alt={footicon3} className="footicon" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
