import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../css/product_detail.styles.css";
import { toast } from "react-toastify";
import useStore from "../utils/store";
import { useNavigate } from "react-router-dom";
import { getProductById } from "../utils/api.js";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet";

const getProductStatus = (product, selectedIndex, cart) => {
  if (product !== undefined) {
    let hashValue =
      product._id +
      product.productItem[selectedIndex].productQuantity +
      product.productItem[selectedIndex].productQuantityType;

    for (let index = 0; index < cart.length; index++) {
      if (
        hashValue ===
        cart[index].product +
          cart[index].productQuantity +
          cart[index].productQuantityType
      ) {
        return cart[index].orderedQuantity;
      }
    }
    return 0;
  }
  return 0;
};

const ProductDescription = () => {
  const { productid } = useParams();

  const navigate = useNavigate();

  const cart = useStore((state) => state.cart);
  const updateCart = useStore((state) => state.updateCart);

  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState(undefined);
  const [productCount, setProductCount] = useState(0);
  const [selectedDropDown, setSelectDropDown] = useState(0);
  const [price, setPrice] = useState(0);
  const [selectedImage, setSelectedImage] = useState(0);
  const [showWithGst, setShowWithGst] = useState(false);

  const getProducts = async () => {
    setIsLoading(true);
    var response = await getProductById(productid);
    if (response[0]) {
      const product = response[1];
      setProduct(product);
      if (product.showInclusiveTax) {
        setShowWithGst(false);
        setPrice(product.productItem[0].productPrice);
      } else {
        setShowWithGst(true);
        setPrice(product.productItem[0].productPriceWithGst);
      }
      const productStatus = getProductStatus(response[1], 0, cart);
      setProductCount(productStatus);
    } else {
      toast(`${response[1]}`, { type: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    const productStatus = getProductStatus(product, selectedDropDown, cart);
    setProductCount(productStatus);
  }, [cart, selectedDropDown]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="product-page">
          <Helmet>
            <title>Crème n Crisp | {product.productName}</title>
            <meta name="description" content={product.productName} />
            <meta
              name="keywords"
              content="Bakery, cookies, cakes, pastries, mocktails"
            />
          </Helmet>
          <div className="title_background_container">
            <h2 className="product-heading">{product.productName}</h2>
          </div>
          <div className="product-main">
            <div className="product-preview">
              {product.productImage.map((pImage, index) => (
                <div>
                  {selectedImage === index ? (
                    <div> </div>
                  ) : (
                    <div
                      className="preview-item"
                      key={index}
                      onClick={(e) => {
                        setSelectedImage(index);
                      }}
                    >
                      <img
                        src={pImage}
                        alt={"just"}
                        className="product-preview-img"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="product">
              <div className="product-dynamic">
                <img
                  src={product.productImage[selectedImage]}
                  alt="just"
                  className="product-img"
                />
                <div className="product-button">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      let hashValue =
                        product._id +
                        product.productItem[selectedDropDown].productQuantity +
                        product.productItem[selectedDropDown]
                          .productQuantityType;
                      for (let index = 0; index < cart.length; index++) {
                        if (
                          hashValue ===
                          cart[index].product +
                            cart[index].productQuantity +
                            cart[index].productQuantityType
                        ) {
                          if (cart[index].orderedQuantity === 1) {
                            cart.splice(index, 1);
                            updateCart([...cart]);
                            break;
                          } else {
                            let newCart = {
                              product: cart[index].product,
                              productQuantity: cart[index].productQuantity,
                              productPrice: cart[index].productPrice,
                              productQuantityType:
                                cart[index].productQuantityType,
                              orderedQuantity: cart[index].orderedQuantity - 1,
                              totalPrice: Math.round(
                                cart[index].totalPrice -
                                  cart[index].productPriceWithGst,
                                2
                              ),
                              productName: cart[index].productName,
                              productImage: cart[index].productImage,
                              gst: cart[index].gst,
                              showInclusiveTax: cart[index].showInclusiveTax,
                              productPriceWithGst:
                                cart[index].productPriceWithGst,
                              productPriceWithGstTotal: Number(
                                (
                                  cart[index].productPriceWithGstTotal -
                                  cart[index].productPriceWithGst
                                ).toFixed(2)
                              ),
                            };
                            cart.splice(index, 1);
                            updateCart([...cart, newCart]);
                            break;
                          }
                        }
                      }
                    }}
                  >
                    -
                  </button>
                  <h2>{productCount}</h2>
                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      let hashValue =
                        product._id +
                        product.productItem[selectedDropDown].productQuantity +
                        product.productItem[selectedDropDown]
                          .productQuantityType;
                      for (let index = 0; index < cart.length; index++) {
                        if (
                          hashValue ===
                          cart[index].product +
                            cart[index].productQuantity +
                            cart[index].productQuantityType
                        ) {
                          let newCart = {
                            product: cart[index].product,
                            productQuantity: cart[index].productQuantity,
                            productPrice: cart[index].productPrice,
                            productQuantityType:
                              cart[index].productQuantityType,
                            orderedQuantity: cart[index].orderedQuantity + 1,
                            totalPrice: Math.round(
                              cart[index].totalPrice +
                                cart[index].productPriceWithGst,
                              2
                            ),
                            productName: cart[index].productName,
                            productImage: cart[index].productImage,
                            showInclusiveTax: cart[index].showInclusiveTax,
                            gst: cart[index].gst,
                            productPriceWithGst:
                              cart[index].productPriceWithGst,
                            productPriceWithGstTotal: Number(
                              (
                                cart[index].productPriceWithGstTotal +
                                cart[index].productPriceWithGst
                              ).toFixed(2)
                            ),
                          };
                          cart.splice(index, 1);
                          updateCart([...cart, newCart]);
                          return;
                        }
                      }
                      let newCart = {
                        product: product._id,
                        productQuantity:
                          product.productItem[selectedDropDown].productQuantity,
                        productPrice:
                          product.productItem[selectedDropDown].productPrice,
                        productQuantityType:
                          product.productItem[selectedDropDown]
                            .productQuantityType,
                        orderedQuantity: 1,
                        totalPrice:
                          product.productItem[selectedDropDown]
                            .productPriceWithGst,
                        productName: product.productName,
                        productImage: product.productImage[0],
                        showInclusiveTax: product.showInclusiveTax,
                        gst: product.gst,
                        productPriceWithGst: Number(
                          product.productItem[
                            selectedDropDown
                          ].productPriceWithGst.toFixed(2)
                        ),
                        productPriceWithGstTotal: Number(
                          product.productItem[
                            selectedDropDown
                          ].productPriceWithGst.toFixed(2)
                        ),
                      };

                      updateCart([...cart, newCart]);
                    }}
                  >
                    +
                  </button>
                </div>
                {productCount > 0 ? (
                  <div className="product-buy-button-div">
                    <button
                      className="product-buy-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/cart");
                      }}
                    >
                      Buy Now
                    </button>
                  </div>
                ) : (
                  <div> </div>
                )}
              </div>

              <br />
              <div className="product-disc">
                <select
                  className="product-cat-weight"
                  onChange={(e) => {
                    setSelectDropDown(e.target.value);
                    let newPrice = showWithGst
                      ? product.productItem[e.target.value].productPriceWithGst
                      : product.productItem[e.target.value].productPrice;
                    setPrice(newPrice);
                  }}
                >
                  {product.productItem.map((item, index) => (
                    <option value={index} key={index}>
                      <div>
                        <div>
                          {item.productQuantity +
                            " " +
                            item.productQuantityType}
                        </div>
                      </div>
                    </option>
                  ))}
                </select>

                <h3 className="product-price">₹{Number(price.toFixed(2))}</h3>

                <h1 className="product-desc-heading">Description :</h1>
                <p className="product-para">{product.productDescription}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDescription;
