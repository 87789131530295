import React, { useState } from "react";
import "../css/address.styles.css";
import { toast } from "react-toastify";
import useStore from "../utils/store";
import { userAddAddress } from "../utils/api.js";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading.jsx";
import { Helmet } from "react-helmet";

function AddressPage() {
  const navigate = useNavigate();

  const [ploatNumber, setPloatNumber] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [pincode, setPincode] = useState();
  const [area, setArea] = useState("");
  const [landmark, setLandmark] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const jwt = useStore((state) => state.jwt);

  const addAddress = async (e) => {
    e.preventDefault();
    let newAddressValue =
      ploatNumber +
      " " +
      streetNumber +
      " " +
      area +
      " " +
      landmark +
      " " +
      pincode;

    setIsLoading(true);
    var response = await userAddAddress(jwt, 0.0, 0.0, newAddressValue);
    if (response[0]) {
      setPloatNumber("");
      setStreetNumber("");
      setPincode("");
      setArea("");
      setLandmark("");
      // toast("Address added", { type: "success" });
      navigate(-1);
    } else {
      toast(`${response[1]}`, { type: "error" });
    }
    setIsLoading(false);
  };

  return (
    <div className="add-address-page">
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div className="add-address-container" >
          <Helmet>
            <title>Crème n Crisp | Address</title>
            <meta name="description" content="Address" />
            <meta name="keywords" content="Address" />
            <link rel="canonical" href="https://www.cremencrisp.com/add-address" />
          </Helmet>
          <form onSubmit={addAddress}>
            <div className="coustomer-bill">
              <h1 className="coustomer-heading">Address</h1>
              <div className="coustomer-add">
                <div className="coustomer-first">
                  <input
                    className="add-input"
                    placeholder="Plot Number"
                    onChange={(e) => {
                      setPloatNumber(e.target.value);
                    }}
                    value={ploatNumber}
                    required
                  />

                  <input
                    className="add-input"
                    placeholder="Street Name"
                    onChange={(e) => {
                      setStreetNumber(e.target.value);
                    }}
                    value={streetNumber}
                    required
                  />

                  <input
                    className="add-input"
                    placeholder="Pin Code"
                    type="number"
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;

                      if (e.target.value === "" || re.test(e.target.value)) {
                        setPincode(e.target.value);
                      }
                    }}
                    value={pincode}
                    required
                  />
                </div>
                <div className="coustomer-second">
                  <input
                    className="add-input"
                    placeholder="Area"
                    onChange={(e) => {
                      setArea(e.target.value);
                    }}
                    value={area}
                    required
                  />
                  <input
                    className="add-input"
                    placeholder="Landmark"
                    onChange={(e) => {
                      setLandmark(e.target.value);
                    }}
                    value={landmark}
                    required
                  />
                </div>
              </div>
              <button className="coustomer-add-btn" type="submit">
                Checkout
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default AddressPage;
