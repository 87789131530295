import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import "../css/menu.styles.css";

const ProductContainer = ({ products }) => {
  return (
    <div className="category__container">
      <div className="category__container_gridcontainer">
        <Box sx={{ flexGrow: 1 }} className="category__container_gridbox">
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={{ xs: 2, md: 3 }}
            columns={{
              xs: 4,
              sm: 12,
              md: 24,
            }} /**multiple of 4 {item size is 4} */
          >
            {products.map((product, index) => (
              <Link to={`/product/${product._id}`} className="link" key={index}>
                <Grid item xs={4}>
                  <div className="category__container_grid_content_container">
                    <img
                      src={product.productImage[0]}
                      alt={product.productName}
                      loading="lazy"
                      className="category__container_grid_content_container_image"
                    />
                    <div className="category__container_grid_content_container_title">
                      {product.productName}
                    </div>
                    {/* <div className="category__container_grid_content_container_title">
                      {product.productDescription}
                    </div> */}
                  </div>
                </Grid>
              </Link>
            ))}
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default ProductContainer;
