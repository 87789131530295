import axios from "axios";
import { orderStatusUpdateSocket, orderCancelSocket } from "./socket_api.js";

const URL = "https://creme-n-crisp-backend.onrender.com";


export const userLogin = async (data) => {
  try {
    var response = await axios.post(`${URL}/user/login`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const userSignup = async (data) => {
  try {
    var response = await axios.post(`${URL}/user/signup`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getProducts = async () => {
  try {
    var response = await axios.get(`${URL}/products`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getCategories = async () => {
  try {
    var response = await axios.get(`${URL}/categories`);

    if (response.status === 200) {
      return [true, response.data];
    }

    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

/**
 *
 * @param {String } categoryId
 * @returns [ success  ,listofproducts or error ]
 */
export const getProductsByCategoryId = async (categoryId) => {
  try {
    const response = await axios.get(`${URL}/category/products/${categoryId}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

/**
 *
 * @param {String } productId
 * @returns [ success  ,product or error ]
 */
export const getProductById = async (productId) => {
  try {
    const response = await axios.get(`${URL}/product/${productId}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const userAddAddress = async (token, latitude, longitude, address) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${URL}/user/address/add`,
      {
        coordingates: [latitude, longitude],
        address: address,
      },
      config
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const userGetAllAddress = async (token) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${URL}/user/address`, config);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const userGetWalletBalance = async (token) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(`${URL}/user/wallet/balance`, config);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

/**
 *
 * @param {String} categoryId
 * @param {String} storeId
 * @returns
 */
export const getProductByStoreId = async (categoryId, storeId) => {
  try {
    const response = await axios.post(`${URL}/product/bystore`, {
      categoryId: categoryId,
      storeId: storeId,
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

/**
 *
 * @param {String} token
 * @param {String} storeId
 * @param {["Delivery", "Pickup"]} type
 * @param {bool} isPickupOrder
 * @param {bool} isDeliveryOrder
 * @param {double} totalAmount
 * @param {double} deliveryCharges
 * @param {[Orderitem]} orderItems
 * @param {String} addressId
 * @returns
 */
export const userCreateOrder = async (
  token,
  storeId,
  type,
  isPickupOrder,
  isDeliveryOrder,
  totalAmount,
  deliveryCharges,
  orderItems,
  addressId,
  discountAmount,
  preTaxAmount,
  postTaxAmount,
  message
) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const body =
      addressId !== undefined
        ? {
            storeId: storeId,
            type: type,
            isPickupOrder: isPickupOrder,
            isDeliveryOrder: isDeliveryOrder,
            totalAmount: totalAmount,
            deliveryCharges: deliveryCharges,
            orderStatus: "Ordered",
            orderItems: orderItems,
            userAddress: addressId,
            discountAmount: discountAmount,
            preTaxAmount: preTaxAmount,
            postTaxAmount: postTaxAmount,
            message: message,
          }
        : {
            storeId: storeId,
            type: type,
            isPickupOrder: isPickupOrder,
            isDeliveryOrder: isDeliveryOrder,
            totalAmount: totalAmount,
            deliveryCharges: deliveryCharges,
            orderStatus: "Ordered",
            orderItems: orderItems,
            discountAmount: discountAmount,
            preTaxAmount: preTaxAmount,
            postTaxAmount: postTaxAmount,
            message: message,
          };
    const response = await axios.post(`${URL}/user/order/create`, body, config);
    if (response.status === 200) {
      orderStatusUpdateSocket(URL, response.data._id, storeId, "New Order");
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

/**
 *
 * @param {String} token
 * @returns
 */

export const userGetAllOrders = async (token) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${URL}/user/orders`, config);
    if (response.status === 200) {
      return [true, response.data];
    }
  } catch (error) {
    return [false, error];
  }
};

export const userGetOrderById = async (token, orderid) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${URL}/user/order/${orderid}`, config);
    if (response.status === 200) {
      return [true, response.data];
    }
  } catch (error) {
    return [false, error];
  }
};

export const getCategoryById = async (categoryId) => {
  try {
    const response = await axios.get(`${URL}/category/${categoryId}`);
    if (response.status === 200) {
      return [true, response.data];
    }
  } catch (error) {
    return [false, error];
  }
};

export const bulkOrder = async (data) => {
  try {
    const response = await axios.post(`${URL}/bulkorder`, data);
    if (response.status === 200) {
      orderStatusUpdateSocket(URL, "", "New Bulk Order");
      return [true, response.data];
    }
    return [false, "Try Again"];
  } catch (error) {
    return [false, error];
  }
};

export const getNearByStore = async (data) => {
  try {
    const response = await axios.post(`${URL}/nearby/store`, data);
    if (response.status === 200) {
      return [true, 200, response.data];
    }
    if (response.status === 202) {
      return [true, 202, response.data];
    }
    return [false, response.data.message.toString()];
  } catch (error) {
    return [false, error];
  }
};

export const getAllStores = async () => {
  try {
    const response = await axios.get(`${URL}/allstores`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data.message.toString()];
  } catch (error) {
    return [false, error];
  }
};
export const getUserProfile = async (token) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${URL}/user/profile`, config);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data.message.toString()];
  } catch (error) {
    return [false, error];
  }
};

export const searchProducts = async (storeId, query) => {
  try {
    const response = await axios.post(`${URL}/search/products`, {
      storeId: storeId,
      query: query,
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data.message.toString()];
  } catch (error) {
    return [false, error];
  }
};

export const getAllAvailableCoupons = async (token) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${URL}/user/coupons`, config);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

/**
 *
 * @param {String} token
 * @param {String} code
 * @param {Number} totalAmount
 * @returns
 */
export const checkIsCouponValid = async (token, code, totalAmount) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${URL}/user/coupon/check-validity`,
      {
        couponCode: code,
        totalAmount: totalAmount,
      },
      config
    );
    if (response.status === 200) {
      return [true, response.data];
    } else if (response.status === 203) {
      return [false, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, { message: "Invalid Coupon" }];
  }
};

/**
 *
 * @param {String} token
 * @param {String} couponId
 */
export const updateCouponStatus = async (token, coupon) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${URL}/user/coupon/apply`,
      {
        coupon: coupon,
      },
      config
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const cancelOrder = async (token, storeId, orderId) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${URL}/user/order/cancel`,
      {
        orderId: orderId,
      },
      config
    );

    if (response.status === 200) {
      orderCancelSocket(URL, storeId, response.data._id);

      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const createOrderId = async (token, amount) => {
  try {
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${URL}/user/create-order-id`,
      {
        amount: amount,
      },
      config
    );

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getAllBanners = async () => {
  try {
    var response = await axios.get(`${URL}/banners`, {});
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
