import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import menu1bg from "../images/Check.webp";
import "../css/product.styles.css";
import Loading from "../components/Loading";
import useStore from "../utils/store";
import { getProductByStoreId, getCategoryById,  getNearByStore  } from "../utils/api";
import ProductContainer from "../components/ProductContainer";
import { Helmet } from "react-helmet";

const Products = () => {
  const { categoryid } = useParams();

  const navigate = useNavigate();

  const isUserLocationSelected = useStore(
    (state) => state.isUserLocationSelected
  );
  const userLocation = useStore((state) => state.userLocation);
  const nearByStore = useStore((state) => state.nearByStore);
  const locationApiCount = useStore((state) => state.locationApiCount);
  const updateUserLocation = useStore((state) => state.updateUserLocation);
  const changeNearbyStoreAvailable = useStore(
    (state) => state.changeNearbyStoreAvailable
  );
  const changeNearbyStore = useStore((state) => state.changeNearbyStore);
  const updateNoNearByStoreAvailable = useStore(
    (state) => state.updateNoNearByStoreAvailable
  );
  const updateLocationApiCount = useStore(
    (state) => state.updateLocationApiCount
  );
  const isNoNearByStoreAvailable = useStore(
    (state) => state.isNoNearByStoreAvailable
  );
  const setIsUserLocationSelected = useStore(
    (state) => state.setIsUserLocationSelected
  );

  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState(() => {
    return [];
  });
  const [category, setCategory] = useState("");

  const getProducts = async () => {
    var response = await getProductByStoreId(categoryid, nearByStore.store._id);
    if (response[0]) {
      setProducts(response[1].products);
    } else {
      toast(`${response[1]}`, { type: "error" });
    }
  };

  const getCurrentCategoryById = async () => {
    var response = await getCategoryById(categoryid);
    if (response[0]) {
      setCategory(response[1]);
    } else {
      toast(`${response[1]}`, { type: "error" });
    }
  };

  const getAllData = async () => {
    setIsLoading(true);
    await getProducts();
    await getCurrentCategoryById();
    setIsLoading(false);
  };

  const checkCurrentLocation = async () => {
    if (
      !isUserLocationSelected ||
      userLocation === null ||
      isNoNearByStoreAvailable
    ) {
      if (locationApiCount > 0) {
        toast("No store available", { type: "error" });
      } else {
        setIsLoading(true);
        navigator.geolocation.getCurrentPosition(
          async function (position) {
            setIsUserLocationSelected(true);
            updateUserLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            var response = await getNearByStore({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            if (response[0]) {
              if (response[1] === 200) {
                updateLocationApiCount();
                changeNearbyStoreAvailable(true);
                changeNearbyStore(response[2]);
                updateNoNearByStoreAvailable(false);
                getAllData();
              } else {
                updateLocationApiCount();
                changeNearbyStoreAvailable(false);
                updateNoNearByStoreAvailable(true);
                toast(`No near by store available`, { type: "error" });
              }
            } else {
              toast(`${response[1]}`, { type: "error" });
            }
          },
          async function () {
            navigate("/select-nearyby-store");
          }
        );
      }
    } else {
      getAllData();
    }
  };

  useEffect(() => {
    checkCurrentLocation();
  }, []);

  return (
    <div className="background__container">
      <Helmet>
        <title>Crème n Crisp | Products</title>
        <meta name="description" content={category.categoryDescription} />
        <meta name="best bakery in Delhi offering varities of cakes, cookies & sweets"/>
        <meta name="keywords" content="Bakery, cookies, cakes, pastries, mocktails" />
      </Helmet>
      <img
        src={menu1bg}
        alt="Creme n Crisp"
        className="background__container_image"
      />

      <div className="background__container_content">
        {isLoading ? (
          <div>
            <br />
          <Loading />
          </div>
        ) : (
          <div className="category-page">
            <div className="category-header">
            <div className="title_background_container">
              <h1 className="category-heading">{category.categoryName}</h1>
              <p className="category-para">{category.categoryDescription}</p>
            </div>
            </div>
              <ProductContainer products={products} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
