import React, { useState, useEffect } from "react";
import "../css/coupon.styles.css";
import Loading from "../components/Loading.jsx";
import { toast } from "react-toastify";
import useStore from "../utils/store";
import { useNavigate } from "react-router-dom";
import { getAllAvailableCoupons } from "../utils/api";
import CouponContainer from "../components/CouponContainer";
import { Helmet } from "react-helmet";

const Coupon = () => {
  const navigate = useNavigate();

  const user = useStore((state) => state.user);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const jwt = useStore((state) => state.jwt);

  const [isLoading, setIsLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);

  const getCoupons = async () => {
    setIsLoading(true);
    const response = await getAllAvailableCoupons(jwt);
    if (response[0]) {
      setCoupons(response[1]);
    } else {
      toast(response[1].message.toString(), {
        type: "error",
      });
    }
    setIsLoading(false);
  };

  const getData = async () => {
    if (isLoggedIn !== undefined && isLoggedIn && user !== undefined) {
      getCoupons();
    } else {
      navigate(`/login`, { replace: true });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="coupon__container">
      <Helmet>
        <title>Crème n Crisp | Coupons</title>
        <meta name="description" content="Coupons" />
        <meta name="keywords" content="Coupons" />
        <link rel="canonical" href="https://www.cremencrisp.com/coupon" />
      </Helmet>
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div>
          <div className="title_background_container">
            <h1 className="coupon__heading">Coupons</h1>
          </div>
          <br />
          <div className="coupon__body__container">
            {coupons.map((coupon, index) => (
              <div>
                {coupon.isActive && coupon.isVisible ? (
                  <CouponContainer coupon={coupon} key={index} />
                ) : (
                  <div></div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Coupon;
