import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyAjTQy6o0lktJSwFoxD_m8QKkXx3BbihYI",
  authDomain: "creme-n-crisp.firebaseapp.com",
  projectId: "creme-n-crisp",
  storageBucket: "creme-n-crisp.appspot.com",
  messagingSenderId: "829971092947",
  appId: "1:829971092947:web:bac2ac41e9bd92a6858b26"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export default app;
