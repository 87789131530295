import { useEffect, useState } from "react";
import "../css/cart.styles.css";
import Radio from "@mui/material/Radio";
import useStore from "../utils/store";
import Loading from "../components/Loading";
import {
  checkIsCouponValid,
  userCreateOrder,
  userGetWalletBalance,
  updateCouponStatus,
  createOrderId,
} from "../utils/api.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const calculateCartTotal = (cart) => {
  let total = 0;
  cart.forEach((item) => {
    total += item.productPriceWithGstTotal;
  });
  return total;
};

function loadRazyorPay() {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";

    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function Cart() {
  const navigate = useNavigate();

  const user = useStore((state) => state.user);
  const cart = useStore((state) => state.cart);
  const updateCart = useStore((state) => state.updateCart);
  const clearCart = useStore((state) => state.clearCart);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const jwt = useStore((state) => state.jwt);
  const nearByStore = useStore((state) => state.nearByStore);
  const finalTotal = useStore((state) => state.finalTotal);
  const updateFinalTotal = useStore((state) => state.updateFinalTotal);
  const isCouponUsed = useStore((state) => state.isCouponUsed);
  const setIsCouponUsed = useStore((state) => state.setIsCouponUsed);
  const couponCode = useStore((state) => state.couponCode);
  const updateCouponCode = useStore((state) => state.updateCouponCode);
  const updateDiscountAmount = useStore((state) => state.updateDiscountAmount);
  const updatePretax = useStore((state) => state.updatePretax);
  const updateMessage = useStore((state) => state.updateMessage);

  const [isLoading, setIsLoading] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [couponValue, setCouponValue] = useState(0);
  const [loyalityPoints, setLoyalityPoints] = useState(0);
  const [gettingLoyalityPoints, setGettingLoyalityPoints] = useState(true);
  const [couponText, setCouponText] = useState(couponCode);
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [cartMessage, setCartMessage] = useState("");

  const placeOrderHandler = () => {
    setIsLoading(true);
    // create an order
    if (selectedValue === "pickup") {
      handlePayment();
    } else {
      let pretax = 0;
      cart.map((product, index) => {
        pretax = pretax + product.productPrice * product.orderedQuantity;
      });
      updateDiscountAmount(
        loyalityPoints +
          Number(couponValue.toFixed(2)) +
          Number(couponValue.toFixed(2))
      );
      updatePretax(pretax);
      updateMessage(cartMessage);
      navigate("/address");
    }
  };

  const placeOrder = async () => {
    let pretax = 0;
    cart.map((product, index) => {
      pretax = pretax + product.productPrice * product.orderedQuantity;
    });

    setIsLoading(true);

    const response = await userCreateOrder(
      jwt,
      nearByStore.store._id,
      "Pickup",
      true,
      false,
      finalTotal,
      0,
      cart,
      undefined,
      loyalityPoints +
        Number(couponValue.toFixed(2)) +
        Number(couponValue.toFixed(2)),
      pretax,
      finalTotal,
      cartMessage
    );
    if (response[0]) {
      if (isCouponUsed) {
        await updateCouponStatus(jwt, couponCode);
      }
      clearCart();
      // toast("Order Created Success", { type: "success" });
    } else {
      toast(response[1].message.toString(), {
        type: "error",
      });
    }
    setIsLoading(false);
    navigate("/orders", { replace: true });
  };
  // "rzp_live_MrNq8tFdke1Oxb
  // rzp_test_80UG1ElgHJyT4J
  async function handlePayment() {
    setIsLoading(true);
    const response = await createOrderId(jwt, finalTotal * 100);

    if (response[0] && response[1].id) {
      const res = await loadRazyorPay();
      if (!res) {
        alert("Razorpay sdk failed");
        return;
      }
      var options = {
        key: "rzp_live_MrNq8tFdke1Oxb",
        amount: finalTotal * 100,
        currency: "INR",
        name: "Creme n Crisp",
        description: "Creme n Crisp Order Transaction",
        image:
          "https://www.cremencrisp.com/static/media/logo.a02453854f117bd76c5f.png",
        handler: function (response) {
          if (
            response.razorpay_payment_id !== undefined &&
            response.razorpay_payment_id !== null
          ) {
            placeOrder();
          } else {
            toast("Payment Failed", { type: "error" });
            setIsLoading(false);
          }
          // alert("payment id"); //response.razorpay_payment_id //! check this
        },
        modal: {
          ondismiss: function () {
            toast("Payment Failed", { type: "error" });
            setIsLoading(false);
          },
        },
        prefill: {
          name: user.name,
          contact: user.phoneNumber,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#f39f9d",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      setIsLoading(false);
      toast(`Payment Fail`, { type: "error" });
    }
  }

  const calculateDeliveryCharges = () => {
    let distance = parseInt(nearByStore.distance);
    if (distance <= 2) {
      setDeliveryCharges(0);
      return 0;
    } else if (distance > 2 && distance <= 5) {
      setDeliveryCharges(40);
      return 40;
    } else {
      setDeliveryCharges(60);
      return 60;
    }
  };

  const getData = async (deliveryCharge) => {
    if (isLoggedIn !== undefined && isLoggedIn && user !== undefined) {
      setIsLoading(true);
      const userWalletBalance = 0;
      const cartValue = calculateCartTotal(cart);
      setCartTotal(cartValue);

      updateFinalTotal(
        cartValue +
          deliveryCharge -
          userWalletBalance -
          loyalityPoints -
          couponValue
      );
      setIsLoading(false);
    } else {
      navigate(`/login`, { replace: true });
    }
  };

  const validateCoupon = async () => {
    if (couponText.length > 0) {
      setIsCouponLoading(true);
      setCouponValue(0);
      const response = await checkIsCouponValid(jwt, couponText, cartTotal);
      if (response[0]) {
        setCouponValue(cartTotal - response[1].newTotal);
        setIsCouponUsed(true);
        updateCouponCode(couponText);
      } else {
        toast(`${response[1].message}`, { type: "error" });
        setIsCouponUsed(false);
        updateCouponCode(null);
      }
      setIsCouponLoading(false);
    }
  };

  const fetchLoyalityPoints = async () => {
    if (isLoggedIn !== undefined && isLoggedIn && user !== undefined) {
      setGettingLoyalityPoints(true);
      const response = await userGetWalletBalance(jwt);
      if (response[0]) {
        setLoyalityPoints(response[1].wallet);
      } else {
        toast(`${response[1]}`, { type: "error" });
      }
      setGettingLoyalityPoints(false);
    }
  };

  const [selectedValue, setSelectedValue] = useState("pickup");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "pickup") {
      setDeliveryCharges(0);
      getData(0);
    } else {
      const newValue = calculateDeliveryCharges();
      getData(newValue);
    }
  };

  const updateCouponValue = async () => {
    if (isCouponUsed) {
      validateCoupon();
    }
  };

  useEffect(() => {
    updateCouponValue();
  }, [cart]);

  useEffect(() => {
    getData(0);
  }, [cart, loyalityPoints, couponValue]);

  useEffect(() => {
    fetchLoyalityPoints();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <Helmet>
            <title>Crème n Crisp | Checkout</title>
            <meta name="description" content="Checkout" />
            <meta name="keywords" content="Checkout" />
            <link rel="canonical" href="https://www.cremencrisp.com/cart" />
          </Helmet>
          <div className="checkout-page">
            <div className="checkout-product">
              <div className="checkout-first">
                <div className="empty-cart">
                  {cart.length < 1 && <div>No Product</div>}
                </div>
                {cart.map((product, index) => (
                  <div key={index} className="cart-image-container">
                    <div className="cart-image-subcontainer">
                      <img
                        src={product.productImage}
                        alt={product.productName}
                        className="cart-product-image"
                      />
                      <div className="cart-detail-card">
                        <div className="cart-product-name">
                          {product.productName}
                        </div>
                        <div className="cart-product-name">
                          {product.productQuantity}
                          {product.productQuantityType}
                        </div>
                        <div className="cart-product-name">
                          ₹{Number(product.productPrice.toFixed(2))}X
                          {product.orderedQuantity}
                        </div>
                        <div className="cart-product-name">
                          ₹
                          {Number(
                            (
                              product.productPrice * product.orderedQuantity
                            ).toFixed(2)
                          )}
                        </div>
                        {!product.includedGst && (
                          <>
                            <div className="cart-product-name">
                              GST: {product.gst}%
                            </div>
                            <div className="cart-product-name">
                              Total: ₹
                              {Number(
                                product.productPriceWithGstTotal.toFixed(2)
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="cart-image-subcontainer2">
                      <div className="button-container">
                        <button
                          className="cart-button"
                          onClick={(e) => {
                            e.preventDefault();
                            let hashValue =
                              product.product +
                              product.productQuantity +
                              product.productQuantityType;
                            for (let index = 0; index < cart.length; index++) {
                              if (
                                hashValue ===
                                cart[index].product +
                                  cart[index].productQuantity +
                                  cart[index].productQuantityType
                              ) {
                                if (cart[index].orderedQuantity === 1) {
                                  cart.splice(index, 1);
                                  updateCart([...cart]);
                                  break;
                                } else {
                                  let newCart = {
                                    product: cart[index].product,
                                    productQuantity:
                                      cart[index].productQuantity,
                                    productPrice: cart[index].productPrice,
                                    productQuantityType:
                                      cart[index].productQuantityType,
                                    orderedQuantity:
                                      cart[index].orderedQuantity - 1,
                                    totalPrice: Math.round(
                                      cart[index].totalPrice -
                                        cart[index].productPriceWithGst,
                                      2
                                    ),
                                    productName: cart[index].productName,
                                    productImage: cart[index].productImage,
                                    gst: cart[index].gst,
                                    showInclusiveTax:
                                      cart[index].showInclusiveTax,
                                    productPriceWithGst:
                                      cart[index].productPriceWithGst,
                                    productPriceWithGstTotal: Number(
                                      (
                                        cart[index].productPriceWithGstTotal -
                                        cart[index].productPriceWithGst
                                      ).toFixed(2)
                                    ),
                                  };
                                  cart.splice(index, 1, newCart);
                                  updateCart([...cart]);
                                  break;
                                }
                              }
                            }
                          }}
                        >
                          -
                        </button>
                        <div>{cart[index].orderedQuantity}</div>
                        <button
                          className="cart-button"
                          onClick={(e) => {
                            e.preventDefault();
                            let hashValue =
                              product.product +
                              product.productQuantity +
                              product.productQuantityType;

                            for (let index = 0; index < cart.length; index++) {
                              if (
                                hashValue ===
                                cart[index].product +
                                  cart[index].productQuantity +
                                  cart[index].productQuantityType
                              ) {
                                let newCart = {
                                  product: cart[index].product,
                                  productQuantity: cart[index].productQuantity,
                                  productPrice: cart[index].productPrice,
                                  productQuantityType:
                                    cart[index].productQuantityType,
                                  orderedQuantity:
                                    cart[index].orderedQuantity + 1,
                                  totalPrice: Math.round(
                                    cart[index].totalPrice +
                                      cart[index].productPriceWithGst,
                                    2
                                  ),
                                  productName: cart[index].productName,
                                  productImage: cart[index].productImage,
                                  showInclusiveTax:
                                    cart[index].showInclusiveTax,
                                  gst: cart[index].gst,
                                  productPriceWithGst:
                                    cart[index].productPriceWithGst,
                                  productPriceWithGstTotal: Number(
                                    (
                                      cart[index].productPriceWithGstTotal +
                                      cart[index].productPriceWithGst
                                    ).toFixed(2)
                                  ),
                                };
                                cart.splice(index, 1, newCart);
                                updateCart([...cart]);
                                return;
                              }
                            }
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="checkout-add">
                <div>
                  <input
                    placeholder="Enter coupon code"
                    className="checkout-coupon"
                    value={couponText}
                    onChange={(e) => {
                      setCouponText(e.target.value);
                    }}
                  />
                  <button
                    className="checkout-coupon-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      validateCoupon();
                    }}
                  >
                    Submit
                  </button>

                  <div className="checkout-final">
                    <div className="checkout-charges">
                      <p> Shipping Charges:</p>
                      <p>
                        {" "}
                        ₹{selectedValue === "pickup" ? 0 : deliveryCharges}
                      </p>
                    </div>
                    <div className="checkout-charges">
                      <p> Loyality Points:</p>
                      {gettingLoyalityPoints ? (
                        <Loading />
                      ) : (
                        <p> ₹{loyalityPoints}</p>
                      )}
                    </div>
                    <div className="checkout-charges">
                      <p> Coupon Discount:</p>
                      <p>
                        {isCouponLoading ? (
                          <Loading />
                        ) : (
                          <div> ₹{Number(couponValue.toFixed(2))}</div>
                        )}
                      </p>
                    </div>
                    <div className="checkout-charges">
                      <p> Cart Value:</p>
                      <p> ₹{Number(cartTotal.toFixed(2))}</p>
                    </div>
                    <div className="checkout-charges">
                      <p> Total:</p>
                      <p> ₹{Number(finalTotal.toFixed(2))}</p>
                    </div>
                  </div>
                  <input
                    placeholder="Message"
                    className="checkout-message"
                    value={cartMessage}
                    onChange={(e) => {
                      setCartMessage(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <br />

            <div className="cart-radio-button">
              <div className="cart-radio-button-text">Pickup</div>
              <Radio
                checked={selectedValue === "pickup"}
                onChange={handleChange}
                value="pickup"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
              <div className="cart-radio-button-text">Delivery</div>
              <Radio
                checked={selectedValue === "delivery"}
                onChange={handleChange}
                value="delivery"
                name="radio-buttons"
                inputProps={{ "aria-label": "B" }}
              />
            </div>

            <div className="checkout-button-container">
              {cart.length > 0 && !gettingLoyalityPoints && !isCouponLoading && (
                <div className="checkout-button-container-wrapper">
                  <div className="checkout-and-continue-button">
                    {finalTotal > 399 && (
                      <button
                        className="checkout-button"
                        onClick={async (e) => {
                          e.preventDefault();

                          if (
                            isLoggedIn !== undefined &&
                            isLoggedIn &&
                            user !== undefined
                          ) {
                            placeOrderHandler();
                          } else {
                            navigate("/login");
                          }
                        }}
                      >
                        Checkout
                      </button>
                    )}

                    <button
                      className="checkout-button continue-button"
                      onClick={async (e) => {
                        navigate("/menu");
                      }}
                    >
                      Continue Shopping
                    </button>
                  </div>
                  <br />
                  {finalTotal > 399 && (
                    <h6>Estimate Delivery Time 90 Minutes</h6>
                  )}
                  {finalTotal > 399 && <br />}
                  {finalTotal < 399 && <h6>Minimum Order Value ₹400/-</h6>}
                </div>
              )}
            </div>

            <br />
          </div>
        </div>
      )}
    </div>
  );
}

export default Cart;
