import React, { useEffect, useState } from "react";
import "../css/order.css";
import "../css/timeline.styles.css";
import { userGetAllOrders } from "../utils/api.js";
import useStore from "../utils/store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import OrderTimeLine from "../components/OrderTimeLine";
import { Helmet } from "react-helmet";

function Orders() {
  const navigate = useNavigate();

  const jwt = useStore((state) => state.jwt);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);

  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  const getAllOrders = async () => {
    setIsLoading(true);
    var response = await userGetAllOrders(jwt);
    if (response[0]) {
      setOrders(response[1]);
    } else {
      toast(`${response[1]}`, { type: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn !== undefined && isLoggedIn && user !== undefined) {
      getAllOrders();
    } else {
      navigate(`/login`, { replace: true });
    }
  }, []);

  return (
    <div className="order_page__container">
      <Helmet>
        <title>Crème n Crisp | Orders</title>
        <meta name="description" content="Orders" />
        <meta name="keywords" content="Orders" />
        <link rel="canonical" href="https://www.cremencrisp.com/orders" />
      </Helmet>
      <div className="background__container_content">
        <div className="title_background_container">
          <h2 className="order-heading">Your Orders</h2>
        </div>
        {isLoading ? (
          <div>
            <br />
            <Loading />
            <br />
          </div>
        ) : (
          <div className="order-main">
            {orders.map((order, index) => (
              <div
                className="order-history"
                key={index}
                onClick={() => {
                  navigate(`/order/${order._id}`);
                }}
              >
                <h4 className="order-id"> Order ID: {order._id} </h4>
                <div className="order-detail">
                  <img
                    src={order.orderItems[0].product.productImage[0]}
                    alt="order"
                    className="order-img"
                  />
                  <div className="order__main_container">
                    <div className="order-disc">
                      <div className="order-name">
                        {order.orderItems[0].product.productName}
                      </div>
                      <div className="order-weight">
                        {order.orderItems[0].productQuantity}
                        {order.orderItems[0].productQuantityType}
                      </div>
                      <div className="order-price">
                        ₹{order.orderItems[0].totalPrice}
                      </div>
                      <div className="order-quan">
                        Qty: {order.orderItems[0].orderedQuantity}
                      </div>
                    </div>
                    <div className="order__timeline_container">
                      <OrderTimeLine
                        isDeliveryOrder={order.isDeliveryOrder}
                        orderStatus={order.orderStatus}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Orders;
