import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import logo from "../images/logo.png";
import cart from "../images/cart.png";
import person from "../images/person.png";
import search from "../images/search.png";
import "../css/AppBar.styles.css";
import { Link } from "react-router-dom";
import LeftDrawer from "./LeftDrawer";
import useStore from "../utils/store.js";

const leftpages = ["HOME", "ABOUT US", "MENU"];
const leftPageUrl = ["/", "/about-us", "/menu"];
const pagesRight = ["CUSTOM ORDERS"];
const rightPageUrl = ["/custom-orders"];

const ResponsiveAppBar = () => {
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);
  const cartlist = useStore((state) => state.cart);

  return (
    <AppBar position="static" sx={{ bgcolor: "#87ddda" }} className="navbar">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <LeftDrawer />
          </Box>

          <Box
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <Link to="/">
              <img src={logo} alt="logo_img" className="app-logo" />
            </Link>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            {/* <Link to="/search" >
              <img src={search} alt="search_image" className="nav-search-icon"  />
            </Link> */}
            {isLoggedIn !== undefined && isLoggedIn && user !== undefined ? (
              <Link to="/cart">
                <img src={cart} alt="logo_img" className="end-images" />
                <div className="cart_list">{cartlist.length}</div>
              </Link>
            ) : (
              <Button
                sx={{
                  my: 2,
                 
                  color: "white",
                  display: "block",
                  fontSize: "1rem",
                }}
              >
                <Link to="/menu" className="navbar__link">
                  Order
                </Link>
              </Button>
            )}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {leftpages.map((page) => (
              <Button
                key={page}
                sx={{
                  my: 2,
                  mx: 3,
                  color: "white",
                  display: "block",
                  fontSize: "1rem",
                }}
              >
                <Link
                  to={leftPageUrl[leftpages.indexOf(page)]}
                  className="navbar__link"
                >
                  {page}
                </Link>
              </Button>
            ))}
          </Box>
          <Box
            component="div"
            sx={{ flexGrow: 2, ml: 14, display: { xs: "none", md: "flex" } }}
          >
            <Link to="/">
              <img src={logo} alt="logo_img" className="app-logo" />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            {pagesRight.map((page) => (
              <Button
                key={page}
                sx={{
                  my: 2,
                  mx: 3,
                  color: "white",
                  display: "block",
                  fontSize: "1rem",
                }}
              >
                <Link
                  to={rightPageUrl[pagesRight.indexOf(page)]}
                  className="navbar__link"
                >
                  {page}
                </Link>
              </Button>
            ))}
            <Link to="/search">
              <img src={search} alt="logo_img" className="nav-search-icon" />
            </Link>
            {isLoggedIn !== undefined && isLoggedIn && user !== undefined ? (
              <div>
                <Link to="/profile">
                  <img src={person} alt="logo_img" className="end-images" />
                </Link>
                <Link to="/cart">
                  <img src={cart} alt="logo_img" className="end-images" />
                  <div className="cart_list">{cartlist.length}</div>
                </Link>
              </div>
            ) : (
              <Button
                sx={{
                  my: 2,
                  mx: 3,
                  color: "white",
                  display: "block",
                  fontSize: "1rem",
                }}
              >
                <Link to="/menu" className="navbar__link">
                  Order Online
                </Link>
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
