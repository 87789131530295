import React from "react";
import "../css/locateUs.css";
import { Helmet } from "react-helmet";

export const LocateUs = () => {
  return (
    <div className="locate-us-page">
      <Helmet>
        <title>Crème n Crisp | Locate Us</title>
        <meta name="description" content="Locate Us" />
        <meta name="keywords" content="Locate Us" />
        <link rel="canonical" href="https://www.cremencrisp.com/locate-us" />
      </Helmet>
      <div className="locate-us-container">
        <div className="location-container">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.942642208828!2d77.25483609999999!3d28.5414437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3dbd2a149fd%3A0x2211ff95d797b316!2sCreme%20N%20Crisp!5e0!3m2!1sen!2sin!4v1655112649728!5m2!1sen!2sin"
            frameBorder="0"
            style={{
              border: "0",
              width: "100%",
              height: "30vh",
            }}
            allowFullScreen=""
            aria-hidden="false"
          />
          <div className="location-text">
            F17, Kalkaji Main Rd, Kalkaji Extension, Kalkaji, New Delhi-110019
          </div>
          <div className="location-text">
            Ph No : 01149407415, +91 9650855873
          </div>
        </div>
        <div className="location-container">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.8962242053435!2d77.2084134!3d28.632872199999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd4f9a11f5cb%3A0xc920a68e4c3d6984!2sCr%C3%A9me%20n%20Crisp!5e0!3m2!1sen!2sin!4v1655112731918!5m2!1sen!2sin"
            frameBorder="0"
            style={{
              border: "0",
              width: "100%",
              height: "30vh",
            }}
            allowFullScreen=""
            aria-hidden="false"
          />
          <div className="location-text">
            97, Bangla Sahib Rd, Sector 4, Gole Market, New Delhi, Delhi 110001
          </div>
          <div className="location-text">
            Ph No : 01123365127, +91 9310369299
          </div>
        </div>
        <div className="location-container">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.512309817744!2d77.1038908!3d28.674317799999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0386989b8641%3A0xe79e73d2023757f0!2sKALEVA%20%26%20Creme%20N%20Crisp!5e0!3m2!1sen!2sin!4v1655112803031!5m2!1sen!2sin"
            frameBorder="0"
            style={{
              border: "0",
              width: "100%",
              height: "30vh",
            }}
            allowFullScreen=""
            aria-hidden="false"
          />
          <div className="location-text">
            A6/8, Ground Floor, East Paschim Vihar New Delhi Delhi-110063
          </div>
          <div className="location-text">
            Ph No : 01140159018, +91 9821158852
          </div>
        </div>
      </div>
    </div>
  );
};
