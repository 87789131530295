import React from "react";
import "../css/terms.css";
import { Helmet } from "react-helmet";

export const ReturnPage = () => {
  return (
    <div className="terms_and_conditions_container">
      <Helmet>
        <title>Crème n Crisp | Cancellation/Refund Policy</title>
        <meta name="description" content="Cancellation/Refund Policy" />
        <meta name="keywords" content="Cancellation/Refund Policy" />
        <link rel="canonical" href="https://www.cremencrisp.com/refund-policy" />
      </Helmet>
      <h1>Cancellation/Refund Policy</h1>
      <p>
        Though Crème n Crisp takes pride in the top notch quality of its
        products and strives to provide a memorable customer experience, there
        are times we fall short by a few crumbs. In such a case of cancellation
        or return, kindly follow the below stated policy.{" "}
      </p>
      <p>
        An online order once placed on Créme n Crisp's website cannot be
        modified or cancelled via any digital medium. You can get updated on the
        status of your order by calling the particular branch you ordered from.{" "}
      </p>
      <p>
        In case of returns, we approve a return only if you receive a damaged or
        wrong product. You can let us know about it by emailing us on
        cremencrisp@gmail.com within 24 hours of receiving the damaged/wrong
        product.{" "}
      </p>
      <p>
        Once the return request has been reviewed and approved by Crème n Crisp
        management, we will arrange for a reverse pick up at an agreed upon
        time. This will be followed by the necessary quality checks. The product
        must be returned in its original condition and packaging accompanied
        with proof of purchase. Once done and approved, we will initiate the
        refund for the same. All refunds are aimed to be processed within 7
        working days.
      </p>
      <p>
        In case the order which is paid through digital payment method is
        cancelled by us due to non-availability of the ordered product at the
        restaurant, the amount is aimed to be refunded to the actual source
        within 7 working days. However, Créme n Crisp is not liable for any
        delay on the bank or the payment gateway's side.
      </p>
      <p>
        By accepting such a replacement or refund, the customer or any other
        party involved consents to waive any additional complaint that could be
        made.
      </p>
    </div>
  );
};
