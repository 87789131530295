import React from "react";
import couponImage from "../images/customo-bg.webp";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../utils/custom_palette.js";

const CouponContainer = ({ coupon }) => {
  return (
    <div className="coupon_container__outer_body">
      <img
        src={couponImage}
        alt="crisp_and_cream_coupon"
        className="coupon__image"
      />
      <div className="coupon__detail_container">
        <div className="coupon__detail_container_header">
        <div className="coupon__code">{coupon.code}</div>
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(coupon.code);
            toast("Code copy", { type: "info" });
          }}
        >
          <ThemeProvider theme={theme}>
            <ContentCopyIcon color="white" />
          </ThemeProvider>
        </IconButton>
        </div>
        <div className="coupon__description">{coupon.description}</div>
      </div>
    </div>
  );
};

export default CouponContainer;
