import React from "react";
import { getCategories } from "../utils/api";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import "../css/menu.styles.css";
import Loading from "../components/Loading";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function MenuPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  const getCategory = async () => {
    setIsLoading(true);
    var response = await getCategories();
    if (response[0]) {
      setCategories(response[1]);
    } else {
      toast(`${response[1]}`, { type: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <div className="background__container_menu">
      <Helmet>
        <title>Crème n Crisp | Menu</title>
        <meta name="description" content="Menu" />
        <meta name="keywords" content="Bakery Menu, Cookies Menu, Cakes" />
        <link rel="canonical" href="https://www.cremencrisp.com/menu" />
      </Helmet>
      <div>
        {isLoading ? (
          <div>
            <br />
            <Loading />
            <br />
          </div>
        ) : (
          <div className="category-page">
            <div className="category-header">
              <div className="title_background_container">
                <h1 className="category-heading">Categories</h1>
                <p className="category-para">
                  At Crème n Crisp, all the creations including sweet delights
                  like cakes, cookies, chocolates etc and savouries like wafers,
                  pastas and pickles are truly unique. Our wide range of breads
                  and salads are sought after by customers. We regularly update
                  our range with seasonal influences taking advantage of what
                  nature does best.
                </p>
              </div>
            </div>
            <div className="category__container">
              <div className="category__container_gridcontainer">
                <Box
                  sx={{ flexGrow: 1 }}
                  className="category__container_gridbox"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={{ xs: 2, md: 3 }}
                    columns={{
                      xs: 4,
                      sm: 12,
                      md: 16,
                    }} /**multiple of 4 {item size is 4} */
                  >
                    {categories.map((category) => (
                      <Link to={`/${category._id}/products`} className="link">
                        <Grid item xs={4}>
                          <div className="category__container_grid_content_container">
                            <img
                              src={category.categoryImage}
                              alt={category.categoryName}
                              loading="lazy"
                              className="category__container_grid_content_container_image"
                            />
                            <div className="category__container_grid_content_container_title">
                              {category.categoryName}
                            </div>
                          </div>
                        </Grid>
                      </Link>
                    ))}
                  </Grid>
                </Box>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MenuPage;
