import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import OrderTimeLine from "../components/OrderTimeLine";
import { toast } from "react-toastify";
import { cancelOrder, userGetOrderById } from "../utils/api";
import useStore from "../utils/store";
import "../css/orderdetail.styles.css";
import Loading from "../components/Loading.jsx";
import { Helmet } from "react-helmet";

const OrderDetailPage = () => {
  const { orderid } = useParams();
  const navigate = useNavigate();

  const jwt = useStore((state) => state.jwt);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);

  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState();


  const userCancelOrder=  async(storeId)=>{
    setIsLoading(true);
    await cancelOrder(jwt, storeId , orderid);
    getOrder() ;
  }

  const getOrder = async () => {
    setIsLoading(true);
    var response = await userGetOrderById(jwt, orderid);
    if (response[0]) {
      setOrder(response[1]);
    } else {
      toast(`${response[1]}`, { type: "error" });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn !== undefined && isLoggedIn && user !== undefined) {
      getOrder();
    } else {
      navigate(`/login`, { replace: true });
    }
  }, []);

  return (
    <div className="orderdetail__container">
      <Helmet>
        <title>Crème n Crisp | Order Detail</title>
        <meta name="description" content="Order Detail" />
        <meta name="keywords" content="Order Detail" />
      </Helmet>
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div className="orderdetail__main_container">
          <h1>Your Orders</h1>
          <div className="orderdetail__content__container">
            <div>{order._id}</div>
            <div className="orderdetail__divider_container">
              <div className="orderproduct__order_container">
                {order.orderItems.map((item, index) => (
                  <div
                    key={index}
                    className="orderproduct__order_container_sub"
                  >
                    <table className="table-orders">
                      <tr>
                        <td></td>
                        <td>
                          <center>Items</center>
                        </td>
                        <td>
                          <center>Quantity</center>
                        </td>
                        <td>
                          <center>Price</center>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <center>
                            <img
                              src={item.product.productImage[0]}
                              alt="crisp-n-cream-product"
                              className="order_container_product_image"
                            />
                          </center>
                        </td>

                        <td>
                          <center>
                            {item.product.productName}
                            <div>
                              {item.productQuantity}
                              {item.productQuantityType}
                            </div>
                          </center>{" "}
                        </td>
                        <td className="orderproduct__quantity_container">
                          {item.orderedQuantity}
                        </td>
                        <td className="orderproduct__price_container">
                          <center>{item.totalPrice}</center>
                        </td>
                        <td className="orderproduct__cancel_container">
                          <center>
                            <button className="orderproduct_cancel_button" onClick={()=>{
                              userCancelOrder(order.storeId);
                            }}>Cancel</button>
                          </center>
                        </td>
                      </tr>
                    </table>
                  </div>
                ))}
              </div>
            </div>
            <br />
            <OrderTimeLine
              isDeliveryOrder={order.isDeliveryOrder}
              orderStatus={order.orderStatus}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetailPage;
