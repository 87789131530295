import React from "react";
import Heart from "../images/about1.jpg";
import Heart2 from "../images/about2.jpg";
import "../css/aboutUs.css";
import story1 from "../images/story-1.png";
import story2 from "../images/story-2.jpg";
import { Helmet } from "react-helmet";

export const AboutUs = () => {
  return (
    <div className="about_us_container">
      <Helmet>
        <title>Crème n Crisp | About</title>
        <meta name="best bakery in Delhi offering varities of cakes, cookies & sweets"/>
        <meta name="description" content="About Us" />
        <meta name="keywords" content="About Us" />
        <link rel="canonical" href="https://www.cremencrisp.com/about-us" />
      </Helmet>
      <div className="title_background_container">
        <h1>About Us</h1>
      </div>
      <div className="background__container_content">
        <div className="about-page">
          <div className="inside-wrapper">
            <div className="about-inside-wrapper">
              <p className="about-para-1">
                Crème n Crisp, after branching out of its brand Kaleva Sweets,
                has been helmed by Anshul and Prankul Aggarwal in the year 2013.
                Conquering new frontiers every year, Anshul and Prankul steered
                the bakery to success with winning the Trendsetter Bakery of the
                Year in 2019 at the esteemed Times Food Awards.
              </p>
              <img src={Heart} alt="creme" className="about_container_image" />
            </div>
            <div className="about-inside-wrapper-2">
              <img src={Heart2} alt="creme" className="about_container_image" />
              <p className="about-para-2">
                Following their father, Jawahar Lal Aggarwal's footsteps, the
                brothers had came up with the riveting concept for a bakery,
                founded on the strong ethics and values of their father, with
                the view to expand the brand Kaleva and include more mouth
                watering desserts and dishes in Kaleva's already sensationally
                diverse platter.
              </p>
            </div>
            <div className="about-inside-wrapper">
              <p className="about-para-1">
                Anshul, the elder of the two brothers, is the dough puncher
                rolling out luscious delicacies from the bakery's kitchen. A
                culinary graduate from one of country's top colleges, IHM, Pusa,
                Anshul's love for cooking tastefully garnered him a Gold Medal
                at the Culinary Art India, AAHAR 2012. He also went on to
                represent India at the WorldSkills International, a prestigious
                international cullinary challenge.
              </p>
              <img src={story1} alt="creme" className="about_container_image" />
            </div>
            <div className="about-inside-wrapper-2">
              <img src={story2} alt="creme" className="about_container_image" />
              <p className="about-para-2">
                Prankul, the younger brother, heads the business front of the
                venture churning out innovative strategies taking Crème n Crisp
                to new highs every year. An ace entrepreneur, Prankul has driven
                the bakery to success with his commitment to innovation and keen
                eye for detail and perfection. The foundation of the bakery, he
                believes, is selling food that everyone loves, economically
                without compromising the quality of ingredients and the final
                taste.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};
