import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import Checkbox from "@mui/material/Checkbox";
import "../css/select_store.styles.css";
import location from "../images/locateUs/location.png";
import { getAllStores } from "../utils/api";
import useStore from "../utils/store.js";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../utils/custom_palette.js";
import { Helmet } from "react-helmet";

function SelectNearByStore() {
  const navigate = useNavigate();

  //! store
  const updateLocationApiCount = useStore(
    (state) => state.updateLocationApiCount
  );
  const changeNearbyStoreAvailable = useStore(
    (state) => state.changeNearbyStoreAvailable
  );
  const changeNearbyStore = useStore((state) => state.changeNearbyStore);
  const updateNoNearByStoreAvailable = useStore(
    (state) => state.updateNoNearByStoreAvailable
  );
  const updateUserLocation = useStore((state) => state.updateUserLocation);
  const setIsUserLocationSelected = useStore(
    (state) => state.setIsUserLocationSelected
  );

  //! hooks
  const [isLoading, setIsLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  //! helper-functions
  const getData = async () => {
    setIsLoading(true);
    const response = await getAllStores();
    if (response[0]) {
      setStores([...response[1]]);
    } else {
      toast(`${response[1]}`, { type: "error" });
    }
    setIsLoading(false);
  };

  const updateLocation = async () => {
    setIsUserLocationSelected(true);
    updateUserLocation({
      latitude: stores[selectedIndex].storeCoordinates[0],
      longitude: stores[selectedIndex].storeCoordinates[1],
    });
    updateLocationApiCount();
    changeNearbyStoreAvailable(true);
    changeNearbyStore({
      isNearBy: true,
      distance: 7,
      store: stores[selectedIndex],
    });
    updateNoNearByStoreAvailable(false);
    navigate(-1);
  };

  //!
  useEffect(() => {
    getData();
  }, []);

  //!
  return (
    <div className="select_store__container">
      <Helmet>
        <title>Crème n Crisp | Store</title>
        <meta name="description" content="Store" />
        <meta name="keywords" content="Bakery Store, Cookies Store, Cakes" />
      </Helmet>
      <div>
        <h2 className="store-heading">Select Store</h2>
      </div>
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div>
          <div className="stores">
            {stores.map((store, index) => (
              <div className="store-indi" key={index}>
                <img src={location} alt="loc" className="location-img" />
                <p className="store-add">{store.storeAddress}</p>
                <div className="store-radio">
                  <ThemeProvider theme={theme}>
                    <Checkbox
                      color="pink"
                      className="radio-btn"
                      checked={selectedIndex === index ? true : false}
                      onClick={(e) => {
                        e.preventDefault();
                        let newValue = index;
                        setSelectedIndex(newValue);
                      }}
                    />
                  </ThemeProvider>
                  <label className="store-select" for="dewey">
                    Select this store
                  </label>
                </div>
              </div>
            ))}
          </div >
          <div className="select_store_container">
          <button
            className="select_store_button"
            onClick={(e) => {
              e.preventDefault();
              updateLocation();
            }}
          >
            Select Store
          </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectNearByStore;
