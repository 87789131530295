import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Routes, Route } from "react-router-dom";
import SignUp from "./pages/SignupPage";
import OTPPage from "./pages/OTPPage";
import Home from "./pages/HomePage";
import Login from "./pages/LoginPage";
import Footer from "./components/Footer";
import MenuPage from "./pages/MenuPage";
import Products from "./pages/ProductsPage";
import SelectLocation from "./pages/SelectStore";
import ProductDescription from "./pages/ProductDescriptionPage";
import Cart from "./pages/CartPage";
import AddressPage from "./pages/AddressPage";
import Orders from "./pages/OrdersPage";
import { getNearByStore } from "./utils/api.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResponsiveAppBar from "./components/Navbar";
import AddressSelectionPage from "./pages/AddressSelectionPage";
import { AboutUs } from "./pages/AboutUs";
import CustomOrders from "./pages/CustomOrders";
import useStore from "./utils/store";
import Profile from "./pages/Profile";
import Search from "./pages/Search";
import Coupon from "./pages/Coupon";
import OrderDetailPage from "./pages/OrderDetailPage";
import { TermsPage } from "./pages/TermsPage";
import { ReturnPage } from "./pages/ReturnPage";
import { PrivacyPage } from "./pages/PrivacyPage";
import PromotionCoupon from "./components/PromotionCoupon";
import { LocateUs } from "./pages/LocateUs";
import { Helmet } from "react-helmet";

function App() {
  const isUserLocationSelected = useStore(
    (state) => state.isUserLocationSelected
  );
  const setIsUserLocationSelected = useStore(
    (state) => state.setIsUserLocationSelected
  );
  const userLocation = useStore((state) => state.userLocation);
  const updateUserLocation = useStore((state) => state.updateUserLocation);
  const changeNearbyStoreAvailable = useStore(
    (state) => state.changeNearbyStoreAvailable
  );
  const changeNearbyStore = useStore((state) => state.changeNearbyStore);
  const updateNoNearByStoreAvailable = useStore(
    (state) => state.updateNoNearByStoreAvailable
  );
  const locationApiCount = useStore((state) => state.locationApiCount);
  const updateLocationApiCount = useStore(
    (state) => state.updateLocationApiCount
  );

  //! user location
  if (
    locationApiCount === 0 &&
    (!isUserLocationSelected || userLocation === null)
  ) {
    navigator.geolocation.getCurrentPosition(async function (position) {
      setIsUserLocationSelected(true);
      updateUserLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
      var response = await getNearByStore({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
      if (response[0]) {
        if (response[1] === 200) {
          updateLocationApiCount();
          changeNearbyStoreAvailable(true);
          changeNearbyStore(response[2]);
          updateNoNearByStoreAvailable(false);
        } else {
          updateLocationApiCount();
          changeNearbyStoreAvailable(false);
          updateNoNearByStoreAvailable(true);
          toast(`No near by store available`, { type: "error" });
        }
      } else {
        toast(`${response[1]}`, { type: "error" });
      }
    });
  }

  return (
    <div className="App">
      <ToastContainer />
      <PromotionCoupon />
      <ResponsiveAppBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/verify" element={<OTPPage />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/custom-orders" element={<CustomOrders />} />
        <Route path="/:categoryid/products" element={<Products />} />
        <Route path="/product/:productid" element={<ProductDescription />} />
        <Route path="/select-nearyby-store" element={<SelectLocation />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/add-address" element={<AddressPage />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/address" element={<AddressSelectionPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/search" element={<Search />} />
        <Route path="/coupon" element={<Coupon />} />
        <Route path="/order/:orderid" element={<OrderDetailPage />} />
        <Route path="/locate-us" element={<LocateUs />} />
        <Route path="/terms-and-conditions" element={<TermsPage />} />
        <Route path="/refund-policy" element={<ReturnPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
      </Routes>
      <div id="recaptcha-container"></div>
      <Footer />
    </div>
  );
}

export default App;
