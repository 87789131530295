import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { getAllBanners } from "../utils/api";
import "../css/carousel.styles.css";
import { toast } from "react-toastify";

function Banner() {
  const [isLoading, setIsLoading] = useState(true);
  const [banners, setBanners] = useState([]);
  const getBanners = async () => {
    setIsLoading(true);
    var response = await getAllBanners();
    if (response[0]) {
      setBanners(response[1].reverse());
    } else {
      setIsLoading(false);
      toast(`${response[1]}`, { type: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <div>
      <Carousel
        fade
        className="banner_carousel"
        controls={false}
        interval={2500}
      >
        {banners
          .filter((banner) => banner.isEnable === true)
          .map((bannerItem) => (
            <Carousel.Item key={bannerItem._id}>
              <img
                src={bannerItem.bannerImage}
                alt={bannerItem.description}
                className="banner_carousel_item"
              />
            </Carousel.Item>
          ))}
      </Carousel>
    </div>
  );
}

export default Banner;
