import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
      pink:{
          main: "#f39f9d"
      },
      white:{
        main: "#ffffff"
      }
    },
  });