import React, { useEffect, useState } from "react";
import {
  userGetAllAddress,
  userCreateOrder,
  updateCouponStatus,
  createOrderId,
} from "../utils/api.js";
import { toast } from "react-toastify";
import Loading from "../components/Loading.jsx";
import useStore from "../utils/store";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../css/addressSelection.css";

function loadRazyorPay() {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";

    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const AddressSelectionPage = () => {
  const navigate = useNavigate();

  const jwt = useStore((state) => state.jwt);
  const cart = useStore((state) => state.cart);
  const clearCart = useStore((state) => state.clearCart);
  const nearByStore = useStore((state) => state.nearByStore);
  const finalTotal = useStore((state) => state.finalTotal);
  const isCouponUsed = useStore((state) => state.isCouponUsed);
  const couponCode = useStore((state) => state.couponCode);
  const discountAmount = useStore((state) => state.discountAmount);
  const pretax = useStore((state) => state.pretax);
  const message = useStore((state) => state.message);
  const user = useStore((state) => state.user);

  const [isLoading, setIsLoading] = useState(true);
  const [address, setAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);

  const calculateDeliveryCharges = () => {
    let distance = parseInt(nearByStore.distance, 10);
    if (distance <= 2) {
      setDeliveryCharges(0);
      return;
    } else if (distance > 2 && distance <= 5) {
      setDeliveryCharges(40);
      return;
    } else {
      setDeliveryCharges(60);
      return;
    }
  };

  const placeOrderHandler = () => {
    setIsLoading(true);
    // create an order
    handlePayment();
  };

  // rzp_live_MrNq8tFdke1Oxb
  // rzp_test_80UG1ElgHJyT4J

  async function handlePayment() {
    setIsLoading(true);
    const response = await createOrderId(jwt, finalTotal * 100);

    if (response[0] && response[1].id) {
      const res = await loadRazyorPay();
      if (!res) {
        alert("Razorpay sdk failed");
        return;
      }
      var options = {
        key: "rzp_live_MrNq8tFdke1Oxb",
        amount: finalTotal * 100,
        currency: "INR",
        name: "Creme n Crisp",
        description: "Creme n Crisp Order Transaction",
        order_id: response[1].id,
        image:
          "https://www.cremencrisp.com/static/media/logo.a02453854f117bd76c5f.png",
        handler: function (response) {
          if (
            response.razorpay_payment_id !== undefined &&
            response.razorpay_payment_id !== null
          ) {
            placeOrder();
          } else {
            toast("Payment Failed", { type: "error" });
            setIsLoading(false);
          }
          // alert("payment id"); //response.razorpay_payment_id //! check this
        },
        modal: {
          ondismiss: function () {
            toast("Payment Failed", { type: "error" });
            setIsLoading(false);
          },
        },
        prefill: {
          name: user.name,
          contact: user.phoneNumber,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#f39f9d",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      setIsLoading(false);
      toast(`Payment Fail`, { type: "error" });
    }
  }

  const placeOrder = async () => {
    setIsLoading(true);
    const response = await userCreateOrder(
      jwt,
      nearByStore.store._id,
      "Delivery",
      false,
      true,
      finalTotal,
      deliveryCharges,
      cart,
      address[selectedAddress]._id,
      discountAmount,
      pretax,
      finalTotal,
      message
    );
    if (response[0]) {
      if (isCouponUsed) {
        await updateCouponStatus(couponCode);
      }
      clearCart();
      toast("Order Created Success", { type: "success" });

      navigate("/orders", { replace: true });
    } else {
      toast(response[1].message.toString(), { type: "error" });
    }
    setIsLoading(false);
  };

  const getAllAddresses = async () => {
    setIsLoading(true);
    calculateDeliveryCharges();
    var response = await userGetAllAddress(jwt);
    if (response[0]) {
      setAddress(response[1].reverse());
    } else {
      toast(`${response[1]}`, { type: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllAddresses();
  }, []);

  return (
    <div>
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div className="address_selection_page">
          <Box sx={{ flexGrow: 1 }} className="category__container_gridbox">
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={{ xs: 2, md: 3 }}
              columns={{
                xs: 4,
                sm: 8,
                md: 12,
              }} /**multiple of 4 {item size is 4} */
            >
              {address.map((addresses, index) => (
                <Grid item xs={4}>
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedAddress(index);
                    }}
                    className={
                      selectedAddress === index
                        ? "selected_address_container"
                        : "unselected_address_container"
                    }
                  >
                    {addresses.address}
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>

          <div className="add-address-row">
            <button
              className="add-address-button"
              onClick={(e) => {
                navigate("/add-address");
              }}
            >
              Add new address
            </button>
          </div>
          <div className="add-address-row">
            {address.length > 0 && (
              <button
                className="order-button"
                onClick={async (e) => {
                  e.preventDefault();
                  placeOrderHandler();
                }}
              >
                Order Now
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressSelectionPage;
