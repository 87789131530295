import React, { useEffect, useState } from "react";
import useStore from "../utils/store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../components/Loading.jsx";
import { getUserProfile } from "../utils/api.js";
import "../css/profile.style.css";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import WalletImage from "../images/wallet.png";
import OrderImage from "../images/order.png";
import CouponIcon from "../images/coupon-icon.png";
import { Helmet } from "react-helmet";

const Profile = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const jwt = useStore((state) => state.jwt);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);
  const logout = useStore((state) => state.logout);

  const [profile, setProfile] = useState();
  const [wallet, setWallet] = useState(0);

  const getProfile = async () => {
    setIsLoading(true);
    var response = await getUserProfile(jwt);
    if (response[0]) {
      setProfile(response[1]);
      let initialValue = 0;
      response[1].wallet.reduce(
        (previousValue, currentValue) => previousValue + currentValue.balance,
        initialValue
      );
      setWallet(initialValue);
      setIsLoading(false);
      
    } else {
      toast(`${response[1]}`, { type: "error" });
    }
  };

  const checkIsAlreadyUser = async () => {
    if (isLoggedIn !== undefined && isLoggedIn && user !== undefined) {
      getProfile();
    } else {
      navigate(`/login`, { replace: true });
    }
  };

  useEffect(() => {
    checkIsAlreadyUser();
  }, [isLoggedIn]);

  return (
    <div>
      <Helmet>
        <title>Crème n Crisp | Profile</title>
        <meta name="description" content="Profile" />
        <meta name="keywords" content="Profile" />
        <link rel="canonical" href="https://www.cremencrisp.com/profile" />
      </Helmet>
      {isLoading ? (
        <div>
          <br/>
          <Loading />
        </div>
      ) : (
        <div className="profile__container">
          <br/>
          <h1 className="profile__heading">Profile</h1>
          <br/>
          <div className="profile__top_container">
            {/* profile container */}

            <div className="profile__profile_container">
              <div className="profile_user_detail_container">
                <img src={profile.profilePhoto} alt={profile.name + "image"} />
                <div className="profile_user_name">{profile.name}</div>
              </div>
              <div className="profile_user_detail_edit_container">
                <button className="edit_profile_button">
                  Edit Profile
                  <EditIcon style={{ color: "white" }} fontSize="small" />
                </button>
                {/* <div className="profile_user_detail_edit_container__member_since">
                  Member since 30th Jan 2022
                </div> */}
              </div>
            </div>
            {/* ============================== */}

            {/* left balance container */}
            <div className="profile__left_balance_container">
              <h4>Wallet Balance</h4>
              <h5>₹ {wallet}</h5>
            </div>
            {/* ======================================================= */}
          </div>

          <div className="profile__bottom_container">
            {/* order */}            
            <div className="profile__small_container" onClick={()=> navigate("/orders")}>
              <img src={OrderImage} alt="creme-n-crisp" />
              <div>Orders</div>
              <div>check out your orders</div>
            </div>
            
          
           
            {/* Wallet */}
            <div className="profile__small_container"  onClick={()=> navigate("/orders")}>
              <img src={WalletImage} alt="creme-n-crisp" />
              <div>Wallet</div>
              <div>Wallet details & history</div>
            </div>
            
            {/* Coupon */}
            <div className="profile__small_container"  onClick={()=> navigate("/coupon")}>
              <img src={CouponIcon} alt="creme-n-crisp" />
              <div>Coupons</div>
              <div>View coupons available</div>
            </div>

          </div>

          {/* logout button */}
          <br/>
          <button className="profile__logout__button" onClick={(e)=>{
            logout();
          }}>
            Logout
            <LogoutIcon style={{ color: "white" }} fontSize="small" />
          </button>
          <br/>

        </div>
      )}
    </div>
  );
};

export default Profile;
