import React, { useState } from "react";

import ReactDOM from "react-dom";
import { Box, Modal, Typography } from "@mui/material";
import { Image } from "@mui/icons-material";
import cake from "../images/cake.jpg";
const ModalBox = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(true);
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="home__modal">
        <div className="overlay_home_modal"></div>
        <h1 className="home__modal__title">
          Get
          <br /> <span className="large_modal_home">15% OFF</span> <br /> Use
          code
          <br /> <span className="large_modal_home">SWEET15</span> <br />
          On orders above ₹800/-
        </h1>

        <button onClick={() => setOpen(false)} className="close__button__home">
          {/* svg for close button */}
          <svg
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.9393 12L6.9696 15.9697L8.03026 17.0304L12 13.0607L15.9697 17.0304L17.0304 15.9697L13.0607 12L17.0303 8.03039L15.9696 6.96973L12 10.9393L8.03038 6.96973L6.96972 8.03039L10.9393 12Z"
              fill="#080341"
            />
          </svg>
        </button>
      </div>
    </Modal>
  );
};

export default ModalBox;
