import React from "react";
import { useState, useEffect } from "react";
import "../css/home.styles.css";
import story1 from "../images/story-1.png";
import story2 from "../images/story-2.jpg";
import { getCategories } from "../utils/api";
import { toast } from "react-toastify";
import Banner from "../components/carousel";
import Loading from "../components/Loading";
import CardDeck from "../components/MultiCarousel";
import { Helmet } from "react-helmet";
import test1 from "../images/testimonial/cake.png";
import test2 from "../images/testimonial/cake2.png";
import test3 from "../images/testimonial/cake3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Modal from "../components/modal";

function Home() {
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getCategory = async () => {
    var response = await getCategories();
    if (response[0]) {
      setCategory(response[1]);
    } else {
      setIsLoading(false);
      toast(`${response[1]}`, { type: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <div className="home-page">
      <Helmet>
        <meta name="Bakery in Delhi offering sweet delights like cakes, pastries, cookies." />
        <link rel="canonical" href="https://www.cremencrisp.com/" />
      </Helmet>
      <Banner />
      <Modal />
      <div className="home-category">
        <h1 className="cat-heading">Made with love</h1>
        {isLoading ? (
          <div>
            <br />
            <Loading />
            <br />
          </div>
        ) : (
          <CardDeck all_details={category} />
        )}
      </div>
      <div className="testimonial">
        <div className="testHead">Testimonial</div>
        <Swiper
          slidesPerView={3}
          spaceBetween={0}
          loop={true}
          autoplay={true}
          className="testCardContainer"
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
            1324: {
              slidesPerView: 3,
            },
            1400: {
              slidesPerView: 3,
            },
          }}
        >
          <SwiperSlide className="fulltest">
            <Testimonial
              img={test3}
              des="Really loved this cake customised for my parents anniversary. I’d asked them for the cake to be less sweet & they made sure that my demand was fulfilled. Everyone loved it so much it got finished in an hour."
              name="Karan Singh"
            />
          </SwiperSlide>
          <SwiperSlide className="fulltest">
            <Testimonial
              img={test1}
              des="“ CREME N CRISP was beyond AMAZING!. We ordered a chocolate cake to celebrate our brother promotion and the cake was so delicious and most beautiful cake we had. “"
              name="Neelam Sadan"
            />
          </SwiperSlide>
          <SwiperSlide className="fulltest">
            <Testimonial
              img={test2}
              des="“ Loved the cake I ordered for my daughter's birthday. Cake was outstanding and beautiful. It was one of the highlights for my party and guests absolutely loved it. “"
              name="Rashmi Gupta"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="home_story__container">
        <h1 className="home_story__title">Our Story</h1>
        <div className="home_story__content_container">
          <img
            src={story1}
            alt="creme-n-crisp-story"
            className="home_story__content_image_left"
            loading="lazy"
          />
          <div className="home_story__content_body_container">
            <div>
              The humble beginnings of Crème n Crisp, a premium bakery in New
              Delhi, happened with the opening of Kaleva Sweets in the city back
              in 1984.
            </div>
            <br />
            <div>
              Gradually with sustained diligence of its owners, their constant
              appetite for innovation and everlasting love for food, Kaleva has
              grown into a brand to reckon with. Today, all its traditional
              sweets, fusion desserts and a host of savoury delights are enjoyed
              and cherished by many in and around Delhi.
            </div>
            <br />
            <div>
              At Crème n Crisp, all the creations including sweet delights like
              cakes, pastries, cookies, etc and crunchy savouries like wafers,
              pastas and lip smacking pickles are truly unique. Our signature
              collection of a wide variety of chocolates are a perfect gift for
              your loved ones. Our selection of bread are also sought after by
              customers. We regularly update our range with seasonal influences
              that our country delightfully offers, thereby taking advantage of
              what nature does best.
            </div>
          </div>
          <img
            src={story2}
            alt="creme-n-crisp-story"
            className="home_story__content_image_right"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
}

export default Home;

const Testimonial = ({ img, des, name }) => {
  return (
    <div className="testimonialCard">
      <div className="testimonialCard__image">
        <img src={img} alt="testimonial" />
      </div>

      <p className="testimonialCard__text">{des}</p>
      <p className="testimonialCard__name">~{name}</p>
    </div>
  );
};
