import { io } from "socket.io-client";

export const orderStatusUpdateSocket = async (
  url,
  orderId,
  storeId,
  status
) => {
  const socket = io(url, { transports: ["websocket"], autoConnect: true });
  socket.connect();
  socket.emit("order-status-update", {
    orderId: orderId,
    status: status,
    storeId: storeId,
  });
};

export const orderCancelSocket = async (url, storeId, orderId) => {
  const socket = io(url, { transports: ["websocket"], autoConnect: true });
  socket.connect();
  socket.emit("order-cancel", {
    orderId: orderId,
    status: "cancelled",
    storeId: storeId,
  });
};
