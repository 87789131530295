import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import cake from "../images/cupcake.webp";
import "../css/verifyotp.styles.css";
import logo from "../images/logo.png";
import { userLogin } from "../utils/api";
import useStore from "../utils/store";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function OTPPage() {
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const result = useStore((state) => state.result);
  const phoneNumber = useStore((state) => state.phoneNumber);
  const setUser = useStore((state) => state.setUser);
  const setJwt = useStore((state) => state.setJwt);
  const login = useStore((state) => state.login);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);

  const getUserDetails = async () => {
    var response = await userLogin({
      phoneNumber: phoneNumber,
    });
    try {
      if (response[0]) {
        const newUser = {
          id: response[1].user._id,
          name: response[1].user.name,
          phoneNumber: response[1].user.phoneNumber,
          wallet: response[1].user.wallet,
        };
        setUser(newUser);
        setJwt(response[1].token);
        login();
        navigate("/", { replace: true });
        // toast(`Log-in Success`, { type: "success" });
      } else {
        navigate("/signup");
      }
    } catch (error) {
      // toast(error.message, { type: "error" });
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    if (otp === "" || otp === null) {
      toast(`Please enter otp`, { type: "error" });
    }

    try {
      await result.confirm(otp);
      await getUserDetails();
    } catch (err) {
      toast(`${err.message}`, { type: "error" });
      navigate("/login");
    }
  };

  useEffect(() => {
    if (isLoggedIn !== undefined && isLoggedIn && user !== undefined) {
      navigate(`/`, { replace: true });
    }
  }, []);

  return (
    <div className="verify-page">
      <Helmet>
        <title>Crème n Crisp | OTP</title>
        <meta name="description" content="One Time Password" />
        <meta name="keywords" content="OTP" />
        <link rel="canonical" href="https://www.cremencrisp.com/verify" />
      </Helmet>
      <div className="verify-main">
        <h2 className="verify-heading">Verify OTP</h2>
        <div className="verify-mid-sec">
          <img src={cake} alt="cake" className="cakeimg" />
          <div className="vl"></div>
          <div className="middle-right-div">
            <div className="verify-mid-right">
              <img src={logo} alt="seal" className="sealimg" />
              <Form onSubmit={verifyOtp}>
                <Form.Group>
                  <Form.Label className="enter-otp">ENTER THE OTP</Form.Label>
                  <Form.Control
                    type="text"
                    id="otp"
                    placeholder="OTP"
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </Form.Group>
                <button type="submit" className="verify-button">
                  Verify
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OTPPage;
