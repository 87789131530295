import React, { useState } from "react";
import CustomImage from "../images/custom-image.webp";
import "../css/customorder.css";
import { bulkOrder } from "../utils/api.js";
import useStore from "../utils/store";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet";

const CustomOrders = () => {
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("Custom Cakes");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState();

  const clearFields = () => {
    setName("");
    setContactNumber("");
    setEmail("");
    setSubject("Custom Cakes");
    setMessage("");
  };

  const HandleSubmit = async () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("userName", name);
    formData.append("contactNumber", contactNumber);
    formData.append("email", email);
    formData.append("subject", subject);
    formData.append("message", message);
    if (isLoggedIn !== undefined && isLoggedIn && user !== undefined) {
      formData.append("userId", user.id);
    }
    if (file && file !== undefined) {
      formData.append("file", file);
    }

    var response = await bulkOrder(formData);
    if (response[0]) {
      toast("Request has been submitted we will contact you shortly", {
        type: "success",
      });
      clearFields();
    } else {
      toast(`${response[1]}`, { type: "error" });
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div className="background__container_customer_order">
        <Helmet>
          <title>Custom Orders</title>
          <meta name="description" content="Custom Orders" />
          <meta name="keywords" content="Custom Orders" />
          <link
            rel="canonical"
            href="https://www.cremencrisp.com/custom-orders"
          />
        </Helmet>
        <div className="background__container_content_customer_order">
          <div className="custom-order-title">
            <h2>Custom Orders</h2>
            <p>
              We offer a range of bespoke products and services to make every
              celebration a unique experience.
            </p>
          </div>

          <div className="outer-div">
            <div className="custom-order-feature-tile">
              <div>
                <div className="top-text">
                  <h3>GIFTING</h3>
                  <p>
                    A wide selection of corporate gifting options like hampers
                    and gift boxes, as well as personlised gifting options for
                    occasions such as baby showers.
                  </p>
                </div>
                <div className="bottom-text">
                  <h3>CORPORATE CATERING</h3>
                  <p>
                    Lunch / Snack boxes for corporate events such as staff
                    meetings, training days and workshops.
                  </p>
                </div>
              </div>
              <img
                src={CustomImage}
                alt="creme"
                className="customorder_main_container_image"
              />
              <div>
                <div className="top-text">
                  <h3>CUSTOMISED CAKES</h3>
                  <p>
                    Handcrafted and custom-made cakes for all occasions such as
                    theme-based and personalised birthday cakes, exquisite
                    multi-tiered wedding cakes.
                  </p>
                </div>
                <div className="bottom-text">
                  <h3>SPECIAL EVENTS & OCCASIONS</h3>
                  <p>
                    Dessert menus for special events such as weddings,
                    anniversaries, navjotes and other occasions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div className="custom-form-background">
          <div className="custom-order-form-border">
            <div className="custom-order-form-heading">Contact Us</div>
            <div className="custom-order-form">
              <form onSubmit={HandleSubmit}>
                <div className="custom-order-form-row">
                  <input
                    className="add-input"
                    placeholder="Name"
                    required
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <div className="spacer"></div>
                  <input
                    className="add-input"
                    placeholder="Contact Number"
                    required
                    value={contactNumber}
                    onChange={(e) => {
                      setContactNumber(e.target.value);
                    }}
                    type="number"
                  />
                </div>
                <div className="custom-order-form-row">
                  <input
                    className="add-input"
                    placeholder="Email"
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type="email"
                  />
                  <div className="spacer"></div>
                  <select
                    className="add-input"
                    name="cars"
                    id="cars"
                    value={subject}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                  >
                    <option value="Custom Cakes">Custom Cakes</option>
                    <option value="Gift Baskets">Gift Baskets</option>
                    <option value="Feedback">Feedback</option>
                    <option value="Others">Others</option>
                  </select>

                  {/* <input
                    className="add-input"
                    placeholder="Subject"
                    required
                    value={subject}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                  /> */}
                </div>
                <div>
                  <textarea
                    className="add-input-textfield"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="add-input">
                  <input
                    type="file"
                    id="myfile"
                    name="myfile"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                  />
                </div>
                <div className="custom-form-submit">
                  <button type="submit" className="submit-button">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomOrders;
