import create from "zustand";
import { persist } from "zustand/middleware";

let store = (set) => ({
  result: null,
  setResult: (newresult) => set((state) => ({ result: newresult })),
  phoneNumber: null,
  setPhoneNumber: (newNumber) => set((state) => ({ phoneNumber: newNumber })),
  isLoggedIn: false,
  login: () => set((state) => ({ isLoggedIn: true })),
  logout: () =>
    set((state) => ({
      isLoggedIn: false,
      user: null,
      jwt: null,
      result: null,
      phoneNumber: null,
    })),
  jwt: null,
  setJwt: (newJwt) => set((state) => ({ jwt: newJwt })),
  user: null,
  setUser: (newUser) => set((state) => ({ user: newUser })),
  cart: [],
  updateCart: (newCart) => set((state) => ({ cart: newCart })),
  clearCart: () => set((state) => ({ cart: [] , finalTotal:  0 , isCouponUsed : false, couponCode: null, discountAmount: 0,pretax:0  } )),
  isUserLocationSelected: false,
  setIsUserLocationSelected: (val) =>
    set((state) => ({ isUserLocationSelected: val })),
  userLocation: null,
  updateUserLocation: (newLocation) =>
    set((state) => ({ userLocation: newLocation })),
  clearUserLocation: () =>
    set((state) => ({
      userLocation: null,
      isUserLocationSelected: false,
      nearbyStoreAvailable: false,
      nearByStore: null,
      isNoNearByStoreAvailable: true,
      locationApiCount: 0
    })),
  nearbyStoreAvailable: false,
  changeNearbyStoreAvailable: (val) =>
    set((state) => ({ nearbyStoreAvailable: val })),
  nearByStore: null,
  changeNearbyStore: (newId) => set((state) => ({ nearByStore: newId })),
  isNoNearByStoreAvailable: true,
  updateNoNearByStoreAvailable: (val)=> set((state)=>({isNoNearByStoreAvailable:  val})),
  locationApiCount: 0,
  updateLocationApiCount: ()=> set((state)=> ({locationApiCount:  state.locationApiCount +1})),
  finalTotal: 0,
  updateFinalTotal: (newTotal)=> set((state)=> ({finalTotal: newTotal})),
  isCouponUsed: false,
  setIsCouponUsed: (newVal) => set((state)=> ({isCouponUsed:  newVal})),
  couponCode: null,
  updateCouponCode: (newVal) => set((state)=> ({couponCode:  newVal})),
  discountAmount: 0,
  updateDiscountAmount: (newVal) => set((state) =>({ discountAmount: newVal})),
  pretax: 0,
  updatePretax:(newVal) => set((state) =>({ pretax: newVal})),
  message: null,
  updateMessage:(newVal) => set((state) =>({ message: newVal}))
});

store = persist(store, { name: "crisp_and_cream_user_settings" });
// store = devtools(store);


const useStore = create(store);

export default useStore;
