import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { Paper } from "@mui/material";
import logo from "../images/logo.png";
import "../css/mobile_navbar.css";
import useStore from "../utils/store.js";

const noLoggedInMap = [
  "HOME",
  "ABOUT US",
  "PRODUCTS",
  // "LUXURY ITEMS",
  "CUSTOM ORDERS",
  "ORDER ONLINE",
  "LOCATE US",
];

const LoggedInMap = [
  "HOME",
  "ABOUT US",
  "PRODUCTS",
  // "LUXURY ITEMS",
  "CUSTOM ORDERS",
  "PROFILE",
  "SEARCH",
  "LOCATE US",
];

const pageUrlLoggedIn = [
  "/",
  "/about-us",
  "/menu",
  // "/",
  "/custom-orders",
  "/profile",
  "/search",
  "/locate-us",
];
const pageUrlNotLoggedIn = [
  "/",
  "/about-us",
  "/menu",
  // "/",
  "/custom-orders",
  "/menu",
  "/locate-us",
];

export default function LeftDrawer() {
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);
  const cartlist = useStore((state) => state.cart);

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Paper sx={{ backgroundColor: "#87ddda", height: "100%" }}>
      <Box
        sx={{
          width: 250,
        }}
        className="box-left"
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div className="mobile__logo_container">
          <img src={logo} alt="logo_img" className="mobile__app_logo" />
        </div>
        <List>
          {isLoggedIn !== undefined && isLoggedIn && user !== undefined
            ? LoggedInMap.map((text, index) => (
                <Link
                  key={index}
                  to={pageUrlLoggedIn[index]}
                  className="link mobilenavbar__container"
                >
                  <ListItem button key={text}>
                    <ListItemText
                      primary={`${text}`}
                      sx={{ color: "#602912", textAlign: "center" }}
                    />
                  </ListItem>
                  <div className="mobile_navbar__divider" />
                </Link>
              ))
            : noLoggedInMap.map((text, index) => (
                <Link
                  key={index}
                  to={pageUrlNotLoggedIn[index]}
                  className="link mobilenavbar__container"
                >
                  <ListItem button key={text}>
                    <ListItemText
                      primary={text}
                      sx={{ color: "#602912", textAlign: "center" }}
                    />
                  </ListItem>
                  <div className="mobile_navbar__divider" />
                </Link>
              ))}
        </List>
      </Box>
    </Paper>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={toggleDrawer(anchor, true)}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
