import React, { useState, useEffect } from "react";
import "../css/search.css";
import useStore from "../utils/store";
import { getNearByStore } from "../utils/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { searchProducts } from "../utils/api";
import Loading from "../components/Loading";
import ProductContainer from "../components/ProductContainer";
import SearchIcon from "@mui/icons-material/Search";
import { Helmet } from "react-helmet";

const Search = () => {
  const navigate = useNavigate();

  const isUserLocationSelected = useStore(
    (state) => state.isUserLocationSelected
  );
  const userLocation = useStore((state) => state.userLocation);
  const nearByStore = useStore((state) => state.nearByStore);
  const locationApiCount = useStore((state) => state.locationApiCount);
  const updateUserLocation = useStore((state) => state.updateUserLocation);
  const changeNearbyStoreAvailable = useStore(
    (state) => state.changeNearbyStoreAvailable
  );
  const changeNearbyStore = useStore((state) => state.changeNearbyStore);
  const updateNoNearByStoreAvailable = useStore(
    (state) => state.updateNoNearByStoreAvailable
  );
  const updateLocationApiCount = useStore(
    (state) => state.updateLocationApiCount
  );
  const isNoNearByStoreAvailable = useStore(
    (state) => state.isNoNearByStoreAvailable
  );
  const setIsUserLocationSelected = useStore(
    (state) => state.setIsUserLocationSelected
  );

  const [query, setQuery] = useState("");
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const search = async () => {
    if (query.length > 0) {
      setIsLoading(true);
      const response = await searchProducts(nearByStore.store._id, query);
      if (response[0]) {
        setResult(response[1]);
      } else {
        toast(`${response[1]}`, { type: "error" });
      }
      setIsLoading(false);
    }
  };

  const checkCurrentLocation = async () => {
    if (
      !isUserLocationSelected ||
      userLocation === null ||
      isNoNearByStoreAvailable
    ) {
      if (locationApiCount > 0) {
        toast("No store available", { type: "error" });
      } else {
        setIsLoading(true);
        navigator.geolocation.getCurrentPosition(
          async function (position) {
            setIsUserLocationSelected(true);
            updateUserLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            var response = await getNearByStore({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            if (response[0]) {
              if (response[1] === 200) {
                updateLocationApiCount();
                changeNearbyStoreAvailable(true);
                changeNearbyStore(response[2]);
                updateNoNearByStoreAvailable(false);
              } else {
                updateLocationApiCount();
                changeNearbyStoreAvailable(false);
                updateNoNearByStoreAvailable(true);
                toast(`No near by store available`, { type: "error" });
              }
            } else {
              toast(`${response[1]}`, { type: "error" });
            }
          },
          async function () {
            navigate("/select-nearyby-store");
          }
        );
      }
    }
    setIsLoading(false);
    return;
  };

  useEffect(() => {
    checkCurrentLocation();
  }, []);

  return (
    <div className="search__container">
      <Helmet>
        <title>Crème n Crisp | Search</title>
        <meta name="description" content="Search for products" />
        <meta name="keywords" content="Search, Products" />
        <link rel="canonical" href="https://www.cremencrisp.com/search" />
      </Helmet>
      <div className="search__container_search_box">
        <input
          placeholder="Enter product name"
          className="search__container_search_box__input"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
        <button
          className="search__container_search_box__icon"
          onClick={(e) => {
            e.preventDefault();
            search();
          }}
        >
          <SearchIcon style={{ color: "white" }} fontSize="small" />
        </button>
      </div>
      <div className="background__container_content">
        {isLoading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div>
            {result.length > 0 ? (
              <div>
                <ProductContainer products={result} />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
