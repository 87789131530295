import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../css/home.styles.css";

import { Link } from "react-router-dom";

const CardDeck = (props) => {
  const responsive = {
    superdesktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1400, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 3,
    },
    smalltablet: {
      breakpoint: { max: 700, min: 460 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 460, min: 300 },
      items: 1,
    },
    smallMobile: {
      breakpoint: { max: 300, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel responsive={responsive} containerClass="cat-full" autoPlay={true}>
      {props.all_details.map((detail , index) => (
        <Link to={`/${detail._id}/products`} className="link" key={index}>
          <img
            src={detail.categoryImage}
            alt={detail._id}
            className="cat-img"
          />
          <div className="cat-name">{detail.categoryName}</div>
        </Link>
      ))}
    </Carousel>
  );
};

export default CardDeck;
